import styled from "styled-components";

export const Container = styled.div`
  padding: 30px 0;

  display: flex;
  flex-direction: column;
  row-gap: 20px;

  div.pagination {
    width: 100%;

    @media (max-width: 801px) {
      display: none;
    }
  }
`;

export const ParticipantListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.56rem;
`;

export const TicketCompanionsButton = styled.div`
  display: flex;
`;
