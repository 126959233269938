import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { paramsToString } from "utils/paramsToString";

import { EventTicketChart, OverviewTotal } from "types";
import { useGetDateAgo } from "./useGetDateAgo";
import { useEventTicketChartSearch } from "services/Hook/Chart";

interface UseSearchFinancialGeneralParams {
  statusFinancial?: string;
}

export function useSearchEventTicketChart({
  statusFinancial,
}: UseSearchFinancialGeneralParams) {
  const [searchParams] = useSearchParams();
  const { daysFilter } = useGetDateAgo();

  const organizers = searchParams.get("organizer") ?? "";
  const events = searchParams.get("events") ?? "";
  const tickets = searchParams.get("tickets") ?? "";
  const data_gte = searchParams.get("data_gte") ?? "";
  const data_lte = searchParams.get("data_lte") ?? "";
  const data_type = searchParams.get("data_type") ?? "";
  const payment_type = searchParams.get("payment_type") ?? "";
  const payment_status = searchParams.get("payment_status") ?? "";
  const type = searchParams.get("type") ?? "";

  const [paramsString, setParamsString] = useState<URLSearchParams>();

  const { data } = useEventTicketChartSearch<EventTicketChart>({
    paramsString: String(paramsString),
    paramsObject: {
      ...(organizers && { organizers: [organizers] }),
      ...(events && { events: events.split("%") }),
      ...(tickets && { event_tickets: tickets.split("%") }),
      ...(data_gte && { date_end: data_lte }),
      ...(data_lte && { date_start: data_gte }),
      ...(data_type &&
        data_type !== "choose" && {
          date_end:
            daysFilter.find((day) => String(day.id) === data_type)?.date_end ??
            daysFilter[0].date_end,
          date_start:
            daysFilter.find((day) => String(day.id) === data_type)
              ?.date_start ?? daysFilter[0].date_start,
        }),
      ...(payment_type && { payment_type }),
      ...(payment_status && { status: payment_status }),
      ...(type && { type }),
    },
  });

  useEffect(() => {
    const paramsString = new URLSearchParams(
      paramsToString({
        organizers,
        events,
        data_gte,
        data_lte,
        tickets,
        payment_type,
        data_type,
        payment_status,
        type,
      })
    );

    setParamsString(paramsString);
  }, [
    data_gte,
    data_lte,
    events,
    organizers,
    statusFinancial,
    tickets,
    data_type,
    payment_type,
    payment_status,
    type,
  ]);

  return {
    data,
  };
}
