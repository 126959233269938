import { useEffect, useState } from "react";

export const useLocalStorageDate = (key: string) => {
  const [date, setDate] = useState<string | null>(null);

  useEffect(() => {
    const storedDate = localStorage.getItem(key);
    if (storedDate) {
      setDate(storedDate);
    }
  }, [key]);

  const updateDate = () => {
    const newDate = new Date().toISOString();
    localStorage.setItem(key, newDate);
    setDate(newDate);
  };

  return { date, updateDate };
};
