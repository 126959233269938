import ParticipantsHeaderFilter from "pages/CheckInOutNew/components/ParticipantsHeaderFilter";
import { Container, ParticipantListContainer } from "./styles";
import ParticipantsAccordionItem from "pages/CheckInOutNew/components/ParticipantsAccordionItem";
import { Pagination } from "components/Pagination";
import LoadMoreItems from "components/LoadMoreItems";
import { useEffect, useState } from "react";
import { useSearchUserLedsBalance } from "hooks/useSearchUserLedsBalance";

import { LoadingOutlined } from "@ant-design/icons";
import { ContainerLoading } from "../CheckOut/styles";
import { Spin } from "antd";
import { Colors } from "constants/colors";
import { EmptyTicketComponent } from "components/EmptyTicketComponent";
import { useSearchParams } from "react-router-dom";

const FILTER_OPTIONS = ["Todos", "Realizados", "Restantes"];

function Participants() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [typeFilter, setTypeFilter] = useState("Todos");

  const page = searchParams.get("page") ?? "1";

  const {
    data: tickets,
    onChange,
    hasMoreItems,
    handleLoadMorePurchasingsMobile,
    loadingMoreItems,
  } = useSearchUserLedsBalance({});

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 42, color: Colors.primary100 }} spin />
  );

  useEffect(() => {
    const page = searchParams.get("page");

    if (page === "0") {
      setSearchParams({
        ...Object.fromEntries(searchParams.entries()),
        page: "1",
      });
    }
  }, [searchParams, setSearchParams]);

  return (
    <Container>
      <ParticipantsHeaderFilter
        filterBy={FILTER_OPTIONS}
        selected={typeFilter}
        onChange={setTypeFilter}
      />

      <ParticipantListContainer>
        {!tickets ? (
          <ContainerLoading>
            <Spin spinning={true} indicator={antIcon} />
          </ContainerLoading>
        ) : null}

        {tickets && tickets?.results?.length === 0 ? (
          <EmptyTicketComponent subtitle="Sem participantes até o momento !" />
        ) : null}

        {tickets &&
          tickets?.results?.map((ticket) => (
            <ParticipantsAccordionItem
              holderId={ticket.user?._id}
              checkInAt={ticket.checkin_at ?? undefined}
              checkOutAt={ticket.checkout_at ?? undefined}
              holderDocument={ticket.holder_cpf}
              holderName={ticket.holder_name}
              ticketId={ticket._id}
              ticketType={ticket?.event_ticket_id?.name}
            />
          ))}
      </ParticipantListContainer>

      <div className="pagination">
        <Pagination
          totalCountOfRegisters={tickets?.count}
          currentPage={Number(page)}
          onPageChange={onChange}
          registersPerPage={10}
        />
      </div>

      <LoadMoreItems
        handleLoadMore={handleLoadMorePurchasingsMobile}
        fetching={loadingMoreItems}
        hasMoreItems={hasMoreItems}
        length={tickets?.results?.length}
        total={tickets?.count}
      />
    </Container>
  );
}

export default Participants;
