import { useCallback } from "react";
import { RiCloseCircleLine } from "react-icons/ri";
import { Colors } from "constants/colors";
import { useGeneralContext } from "contexts/GeneralContext";
import { ModalStyled } from "./styles";
import { ModalGeneralBalanceReceivableProps } from "./types";
import ReceivableBalance from "./AvailableBalance";

export function ModalGeneralBalanceReceivable({
  balance,
  onWithdraw,
}: ModalGeneralBalanceReceivableProps) {
  const {
    isOpenModalGeneralBalanceReceivable,
    setIsOpenModalGeneralBalanceReceivable,
  } = useGeneralContext();

  const closeModal = useCallback(() => {
    setIsOpenModalGeneralBalanceReceivable(false);
  }, [setIsOpenModalGeneralBalanceReceivable]);

  return (
    <ModalStyled
      width={1200}
      visible={isOpenModalGeneralBalanceReceivable}
      onOk={closeModal}
      onCancel={closeModal}
      style={{ borderRadius: "20px" }}
      closeIcon={
        <RiCloseCircleLine
          style={{ marginTop: "14px", marginRight: "34px" }}
          size={43}
          color={Colors.black40}
        />
      }
      footer={null}
    >
      <ReceivableBalance
        balance={balance}
        onWithdraw={onWithdraw}
        closeModal={closeModal}
      />
    </ModalStyled>
  );
}
