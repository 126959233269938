export function purchasesTypePercentageFormat(purchaseType: string): string {
  const keyMapping: Record<string, string> = {
    checkout: "Checkout",
    "eventx-checkout": "Checkout EventX",
    import: "Importação",
    manual: "Manual",
    xgrow: "Xgrow",
  };

  return keyMapping[purchaseType] || purchaseType;
}

export function paymentsTypePercentageFormat(purchaseType: string): string {
  const keyMapping: Record<string, string> = {
    pix: "Pix",
    "credit-card": "Cartão de Credito",
    boleto: "Boleto",
    xgrow: "Xgrow",
    cortesy: "Cortesia",
    courtesy: "Cortesia",
  };

  return keyMapping[purchaseType] || purchaseType;
}

export function paymentsStatusPercentageFormat(purchaseType: string): string {
  const keyMapping: Record<string, string> = {
    PAID: "Pago",
    PENDING: "Pendente",
    REFUND: "Estornado",
    CANCEL: "Cancelado",
  };

  return keyMapping[purchaseType] || purchaseType;
}
