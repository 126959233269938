import { useCallback, useEffect } from "react";

import { Radio, RadioChangeEvent, Spin, Select } from "antd";

import { Controller, SubmitHandler, useForm } from "react-hook-form";

import { LoadingOutlined } from "@ant-design/icons";

import {
  RiUserLine,
  RiCalendar2Line,
  RiContactsLine,
  RiBuilding4Line,
  RiMapPinAddLine,
  RiCommunityLine,
} from "react-icons/ri";

import { AiOutlineMail } from "react-icons/ai";

import { CgSmartphone } from "react-icons/cg";

import { Colors } from "constants/colors";

import {
  Card,
  Container,
  Divider,
  SubTitle,
  Title,
  TitleWithMargin,
  TitleWithMarginTop,
} from "./styles";

import { ContainerButtons, ButtonPrev, ButtonNext } from "../../styles";

import {
  ContainerEventLocal,
  RowTypeEvent,
} from "pages/MyEvent/New/Steps/Local/styles";

import { GridInputs } from "./styles";

import { InputForm } from "components/Form/InputForm";

import { useCreateOrganizer } from "contexts/CreateOrganizerContext";

import { createOrganizerIdentificationFormSchema } from "lib/validations/createOrganizer";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { IdentificationCreateOrganizer } from "types";
import axios from "axios";
import { CreateOrganizer } from "services/Hook/Organizer";

import moment from "moment";

import { useAuth } from "contexts/AuthContext";
import { errorNotification } from "components/Notification";
import {
  BorderSelect,
  ContainerSelect,
  LabelSelect,
  StyledSelect,
} from "pages/MyEvent/New/Steps/Local/Form/styles";
import { ufs } from "constants/ufs";
import { doCheckEventNicknameAvailability } from "services/Hook/Event";

const { Option } = Select;

export function Identification() {
  const { hydratationUser } = useAuth();

  const {
    register,
    control,
    watch,
    formState,
    handleSubmit,
    setError,
    setFocus,
    setValue,
    clearErrors,
  } = useForm<IdentificationCreateOrganizer>({
    resolver: yupResolver(createOrganizerIdentificationFormSchema),
  });

  const { errors, isSubmitting } = formState;

  const { organizer } = useCreateOrganizer();

  const typeDocument = watch("type_document");

  const addressZipCode = watch("address_zipcode");

  const handleSearchAddress = useCallback(async () => {
    if (addressZipCode) {
      if (addressZipCode.length === 9) {
        try {
          const response = await axios.get(
            `https://viacep.com.br/ws/${addressZipCode}/json/`
          );

          const { logradouro, bairro, localidade, uf } = response.data;

          if (bairro === undefined) {
            setError("address_zipcode", {
              type: "manual",
              message: "Não foi possivel encontrar o Cep",
            });
            return;
          }

          clearErrors("address_zipcode");

          setValue("address_street", logradouro, {
            shouldValidate: true,
            shouldDirty: true,
          });
          setValue("address_disctrict", bairro, {
            shouldValidate: true,
            shouldDirty: true,
          });
          setValue("address_city", localidade, {
            shouldValidate: true,
            shouldDirty: true,
          });
          setValue("address_state", uf, {
            shouldValidate: true,
            shouldDirty: true,
          });
        } catch (err) {
          setError("address_zipcode", {
            type: "required",
            message: "Não foi possivel encontrar o Cep",
          });
        }
      }
    }
  }, [addressZipCode, clearErrors, setError, setValue]);

  const isValidNickname = useCallback(
    async (nickname: string): Promise<boolean> => {
      try {
        await doCheckEventNicknameAvailability(nickname);

        return true;
      } catch {
        return false;
      }
    },
    []
  );

  useEffect(() => {
    if (addressZipCode) {
      if (addressZipCode.length === 9) {
        handleSearchAddress();
      }
    }
  }, [addressZipCode, handleSearchAddress]);

  const handleAddIdentification: SubmitHandler<
    IdentificationCreateOrganizer
  > = async ({ ...rest }) => {
    const {
      address_zipcode,
      address_number,
      address_street,
      address_city,
      address_disctrict,
      address_state,
    } = rest;

    const {
      agent_email,
      agent_birthdate,
      agent_phone,
      type_document,
      agent_name,
      business_email,
      business_phone,
      business_document,
      description,
      document,
      document_city,
      document_name,
      name,
      nickname,
    } = rest;

    const isAvailableNickname = await isValidNickname(nickname);

    if (!isAvailableNickname) {
      setError("nickname", {
        type: "required",
        message: "Nickname já existe",
      });

      return;
    }

    try {
      const dateBirthdate = moment(agent_birthdate, "DD/MM/YYYY");

      const dateWithComas = dateBirthdate.format("YYYY-MM-DD");

      const credentials = {
        type_document,
        agent_name: type_document === 2 ? agent_name : name,
        agent_document: document,
        document: type_document === 1 ? document : business_document,
        agent_birthdate: dateWithComas,
        agent_email: agent_email ?? "",
        agent_phone: agent_phone ?? "",
        name: type_document === 1 ? name : agent_name,
        description: description ?? "Este é um organizador",
        document_name: type_document === 2 ? document_name : name,
        document_city: document_city ?? address_city,
        address_zipcode,
        address_street,
        address_country: "BR",
        address_disctrict,
        address_number: address_number ?? "1",
        address_state,
        address_city,
        address_complement: "",
        business_email: business_email ?? agent_email,
        business_phone: type_document === 1 ? agent_phone : business_phone,
        nickname,
      };

      const response = await CreateOrganizer({ credentials });

      organizer.createIdentification({
        organizerId: response?._id,
        name: type_document === 1 ? name : agent_name,
      });

      hydratationUser();

      organizer.goToNextStep();
    } catch (err: any) {
      if (err.response.data.message === "Documento já cadastrado") {
        errorNotification(
          "Erro ao cadastrar organizador",
          "Documento já cadastrado"
        );

        setError("document", {
          type: "required",
          message: "Documento já cadastrado",
        });

        setFocus("document");

        return;
      }

      if (err.response.data.message === "CNPJ já cadastrado, verifique.") {
        errorNotification(
          "Erro ao cadastrar organizador",
          "CNPJ já cadastrado, verifique."
        );

        setError("business_document", {
          type: "required",
          message: "CNPJ já cadastrado",
        });

        setFocus("business_document");

        return;
      }

      if (err.response.data.error.category === "duplicate_taxpayer_id") {
        errorNotification(
          "Erro ao cadastrar organizador",
          "Não foi possivel cadastrar o organizador, tente novamente"
        );

        if (typeDocument === 1) {
          setError("document", {
            type: "required",
            message: "Documento já cadastrado",
          });

          setFocus("document");
        }
      }

      if (err.response.data.message === "Nickname já existe") {
        errorNotification(
          "Erro ao cadastrar organizador",
          "Não foi possivel cadastrar o organizador, tente novamente"
        );

        setError("nickname", {
          type: "required",
          message: "Nickname já existe",
        });

        setFocus("nickname");
      }

      errorNotification(
        "Erro ao cadastrar organizador",
        JSON.stringify(err?.response?.data?.message ?? "Tente novamente.")
      );
    }
  };

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 24, color: Colors.white }} spin />
  );

  return (
    <Container onSubmit={handleSubmit(handleAddIdentification)}>
      <Card>
        <Title>Qual o tipo de cadastro?</Title>

        <ContainerEventLocal>
          <Controller
            control={control}
            name="type_document"
            defaultValue={1}
            render={({ field: { onChange, value } }) => (
              <Radio.Group
                onChange={(e: RadioChangeEvent) => {
                  onChange(e.target.value);
                }}
                value={value}
              >
                <RowTypeEvent>
                  <Radio className="radio" value={1}>
                    Pessoa Física
                  </Radio>
                  <Radio className="radio" value={2}>
                    Pessoa Jurídica
                  </Radio>
                </RowTypeEvent>
              </Radio.Group>
            )}
          />
        </ContainerEventLocal>

        <Divider />

        {typeDocument === 1 ? (
          <TitleWithMargin>Seus dados de identificação</TitleWithMargin>
        ) : null}

        {typeDocument === 2 ? (
          <TitleWithMargin>Dados do representante da empresa</TitleWithMargin>
        ) : null}

        {typeDocument === 1 ? (
          <InputForm
            isFull
            {...register("name")}
            icon={<RiUserLine size={17} color={Colors.secondary40} />}
            error={errors.name}
            label="Nome completo"
          />
        ) : null}

        {typeDocument === 2 ? (
          <InputForm
            isFull
            {...register("agent_name")}
            icon={<RiUserLine size={17} color={Colors.secondary40} />}
            error={errors.name}
            label="Nome completo"
          />
        ) : null}

        <GridInputs>
          <InputForm
            isFull
            {...register("document")}
            mask="cpf"
            icon={<RiContactsLine size={17} color={Colors.secondary40} />}
            error={errors.document}
            label="CPF"
          />

          <InputForm
            isFull
            {...register("agent_birthdate")}
            mask="birthdate"
            icon={<RiCalendar2Line size={17} color={Colors.secondary40} />}
            error={errors.agent_birthdate}
            label="Data de nascimento"
          />
        </GridInputs>

        <GridInputs>
          <InputForm
            isFull
            {...register("agent_email")}
            icon={<AiOutlineMail size={17} color={Colors.secondary40} />}
            error={errors.agent_email}
            label="E-mail"
          />

          <InputForm
            isFull
            {...register("agent_phone")}
            mask="phone"
            icon={<CgSmartphone size={17} color={Colors.secondary40} />}
            error={errors.agent_phone}
            label="Telefone"
          />
        </GridInputs>

        <GridInputs>
          <InputForm
            isFull
            {...register("address_zipcode")}
            placeholder="Insira o CEP do local..."
            mask="cep"
            error={errors.address_zipcode}
            icon={<RiMapPinAddLine size={17} color={Colors.secondary40} />}
            label="CEP"
          />

          <InputForm
            isFull
            {...register("address_street")}
            placeholder="Insira o endereço do local..."
            error={errors.address_street}
            icon={<RiCommunityLine size={17} color={Colors.secondary40} />}
            label="Endereço"
          />
        </GridInputs>

        <GridInputs>
          <InputForm
            isFull
            {...register("address_number")}
            placeholder="Insira o número do local..."
            error={errors.address_number}
            icon={<RiCommunityLine size={17} color={Colors.secondary40} />}
            label="Número"
          />

          <InputForm
            isFull
            {...register("address_complement")}
            placeholder="Insira o complemento do endereço..."
            error={errors.address_complement}
            icon={<RiCommunityLine size={17} color={Colors.secondary40} />}
            label="Complemento"
          />
        </GridInputs>

        <GridInputs>
          <InputForm
            isFull
            {...register("address_disctrict")}
            placeholder="Insira o bairro do local..."
            error={errors.address_disctrict}
            icon={<RiCommunityLine size={17} color={Colors.secondary40} />}
            label="Bairro"
          />

          <InputForm
            isFull
            {...register("address_city")}
            placeholder="Insira a cidade do endereço..."
            error={errors.address_city}
            icon={<RiCommunityLine size={17} color={Colors.secondary40} />}
            label="Cidade"
          />
        </GridInputs>

        <ContainerSelect>
          <LabelSelect>
            <h4>Estado</h4>
          </LabelSelect>

          <Controller
            control={control}
            name="address_state"
            rules={{ required: "Salutation is required" }}
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { invalid, isTouched, isDirty, error },
              formState,
            }) => (
              <BorderSelect>
                <StyledSelect
                  size="large"
                  labelInValue
                  bordered={false}
                  placeholder="Escolha o estado"
                  value={value}
                  disabled={organizer?.identification !== null}
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                  }}
                  onChange={(e: any) => {
                    onChange(e.value);
                  }}
                >
                  {ufs.map((uf) => {
                    return (
                      <Option value={uf.sigla} key={uf.id}>
                        {uf.nome}
                      </Option>
                    );
                  })}
                </StyledSelect>
              </BorderSelect>
            )}
          />
        </ContainerSelect>

        <InputForm
          isFull
          {...register("nickname")}
          mask="especial"
          hasPrefix
          prefix="eventx.com.br/"
          icon={<RiUserLine size={17} color={Colors.secondary40} />}
          error={errors.nickname}
          label="Link Eventx"
        />

        {typeDocument === 2 ? (
          <>
            <TitleWithMarginTop>Dados da empresa</TitleWithMarginTop>

            <GridInputs>
              <InputForm
                isFull
                {...register("business_document")}
                mask="cnpj"
                icon={<RiContactsLine size={17} color={Colors.secondary40} />}
                error={errors.business_document}
                label="CNPJ"
              />

              <InputForm
                isFull
                {...register("document_name")}
                icon={<RiBuilding4Line size={17} color={Colors.secondary40} />}
                error={errors.document_name}
                label="Razão social"
              />
            </GridInputs>

            <GridInputs>
              <InputForm
                isFull
                {...register("document_name")}
                icon={<RiBuilding4Line size={17} color={Colors.secondary40} />}
                error={errors.document_name}
                label="Nome fantasia"
              />

              <InputForm
                isFull
                {...register("document_city")}
                icon={<RiBuilding4Line size={17} color={Colors.secondary40} />}
                error={errors.document_city}
                label="Inscrição municipal"
              />
            </GridInputs>

            <GridInputs>
              <InputForm
                isFull
                {...register("business_phone")}
                mask="phone"
                icon={<AiOutlineMail size={17} color={Colors.secondary40} />}
                error={errors.business_phone}
                label="Telefone comercial"
              />

              <InputForm
                isFull
                {...register("business_email")}
                icon={<CgSmartphone size={17} color={Colors.secondary40} />}
                error={errors.business_email}
                label="E-mail comercial"
              />
            </GridInputs>
          </>
        ) : null}

        <Divider />

        {isSubmitting ? (
          <>
            <SubTitle>Cadastrando organizador...</SubTitle>
          </>
        ) : null}

        <ContainerButtons>
          <ButtonPrev type="button" onClick={() => organizer.goToBackStep()}>
            <span>Voltar</span>
          </ButtonPrev>

          <ButtonNext
            type="submit"
            isLoading={isSubmitting}
            disabled={isSubmitting}
          >
            <Spin spinning={isSubmitting} indicator={antIcon} />

            <span>Prosseguir</span>
          </ButtonNext>
        </ContainerButtons>
      </Card>
    </Container>
  );
}
