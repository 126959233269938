/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useRef, useState } from "react";

import { useSearchParams } from "react-router-dom";

import { RiCheckboxCircleLine, RiTimeLine } from "react-icons/ri";

import QrScanner from "qr-scanner";

import {
  ButtonCode,
  Container,
  ContainerCamera,
  ContainerFooterButtons,
  ContainerQr,
  ContentContainer,
  DivLastRead,
  Row,
  StatusCheckContainer,
} from "./styles";
import { Colors } from "constants/colors";

import QrFrame from "assets/qr-frame.svg";
import { getUserTicket, makeCheckin } from "pages/CheckInOutNew/services";
import {
  errorNotification,
  successNotification,
} from "components/Notification";

import { UserTicketResponse } from "pages/CheckInOutNew/services/types";
import { IoMdCloseCircleOutline } from "react-icons/io";
import { formatCpf } from "utils/formatCpf";
import { useDisclosure } from "hooks/useDisclosure";
import { ModalManualCheckinOut } from "../ModalManualCheckin";
import { useLocalStorageDate } from "hooks/useLocalStorageDate";

enum ReasonError {
  ALREADY_SCANNED = "Este QR Code já foi validado anteriormente:",
  CHECKIN_FAILED = "Erro ao fazer o check-in",
  USER_TICKET_NOT_ALLOWED_TO_USER = "O ingresso não pertence a este usuario",
  USER_TICKET_NOT_FOUND = "O ingresso não foi encontrado",
}

type Error = {
  response: {
    status: number;
    data: {
      message: string;
    };
  };
};

export function CheckinComponent() {
  const [error, setError] = useState<ReasonError | null>(null);
  const [success, setSuccess] = useState(false);

  const { date: lastCheckin, updateDate: updateCheckin } =
    useLocalStorageDate("lastCheckin");

  const [searchParams] = useSearchParams();

  const tabName = searchParams.get("tab_name") ?? "";

  const scanner = useRef<QrScanner>();
  const videoEl = useRef<HTMLVideoElement>(null);
  const qrBoxEl = useRef<HTMLDivElement>(null);
  const [qrOn, setQrOn] = useState<boolean>(true);
  const [userTicket, setUserTicket] = useState<
    UserTicketResponse | undefined
  >();

  const { isModalVisible, handleCancel, handleOk, showModal } = useDisclosure();

  const handleScanAgain = useCallback(
    ({ reset = true }: { reset?: boolean }) => {
      if (reset) {
        setUserTicket(undefined);
        setSuccess(false);
        setError(null);
      }

      if (!videoEl.current) {
        return;
      }

      scanner.current = new QrScanner(videoEl?.current, onScanSuccess, {
        onDecodeError: () => {},
        preferredCamera: "environment",
        highlightScanRegion: true,
        highlightCodeOutline: false,
        overlay: qrBoxEl?.current || undefined,
      });

      scanner?.current
        ?.start()
        .then(() => setQrOn(true))
        .catch((err) => {
          if (err) setQrOn(false);
        });
    },
    []
  );

  const handleMakeCheckin = useCallback(async (userTicketId: string) => {
    if (!userTicketId) return;

    try {
      await makeCheckin(userTicketId);

      updateCheckin();

      setSuccess(true);

      successNotification("Check-in concluído com sucesso.");

      setTimeout(() => {
        handleScanAgain({ reset: true });
      }, 5000);
    } catch (err) {
      const response = err as Error;

      const { data } = response.response;

      if (data?.message === "user-ticket_already_checked_in") {
        setError(ReasonError.ALREADY_SCANNED);

        errorNotification(
          "Ocorreu um erro ao realizar o check-in.",
          ReasonError.ALREADY_SCANNED
        );
      }

      if (data?.message === "user-ticket_not_allowed_to_user") {
        setError(ReasonError.USER_TICKET_NOT_ALLOWED_TO_USER);

        errorNotification(
          "Ocorreu um erro ao realizar o check-in.",
          ReasonError.USER_TICKET_NOT_ALLOWED_TO_USER
        );
      }

      setTimeout(() => {
        handleScanAgain({ reset: true });
      }, 5000);
    } finally {
    }
  }, []);

  const getTicketCode = useCallback(async (userTicketId: string) => {
    try {
      if (!userTicketId) return;

      const response = await getUserTicket(userTicketId);

      setUserTicket(response);

      handleMakeCheckin(response._id);
    } catch (err) {
      const response = err as Error;

      const { data } = response.response;

      if (data?.message === "user-ticket_not_found") {
        setError(ReasonError.USER_TICKET_NOT_FOUND);

        errorNotification(
          "Ocorreu um erro ao buscar pelo ingreso",
          ReasonError.USER_TICKET_NOT_FOUND
        );
      }

      setTimeout(() => {
        handleScanAgain({ reset: false });
      }, 5000);
    }
  }, []);

  const onScanSuccess = (result: QrScanner.ScanResult) => {
    const data = result?.data;

    if (!data || data.length !== 16) {
      return;
    }

    getTicketCode(data);

    scanner.current?.stop();
  };

  useEffect(() => {
    if (videoEl?.current && !scanner.current) {
      scanner.current = new QrScanner(videoEl?.current, onScanSuccess, {
        onDecodeError: () => {},
        preferredCamera: "environment",
        highlightScanRegion: true,
        highlightCodeOutline: false,
        overlay: qrBoxEl?.current || undefined,
      });

      scanner?.current
        ?.start()
        .then(() => setQrOn(true))
        .catch((err) => {
          if (err) setQrOn(false);
        });
    }

    return () => {
      if (scanner.current) {
        scanner.current.stop();
        scanner.current.destroy();
        scanner.current = undefined;
      }
    };
  }, [tabName]);

  useEffect(() => {
    if (tabName === "checkout") {
      if (scanner.current) {
        scanner.current.stop();
        scanner.current.destroy();
        scanner.current = undefined;
      }
    }
  }, [tabName]);

  useEffect(() => {
    if (!qrOn) alert("Habilite a câmera do seu navegador.");
  }, [qrOn]);

  return (
    <Container>
      <ContainerQr>
        <ContainerCamera>
          <div className="qr-reader">
            {/* QR */}
            <video ref={videoEl}></video>
            <div ref={qrBoxEl} className="qr-box">
              <img
                style={{ visibility: "hidden" }}
                src={QrFrame}
                alt="Qr Frame"
                width={256}
                height={256}
                className="qr-frame"
              />
            </div>
          </div>
        </ContainerCamera>
      </ContainerQr>

      {userTicket ? (
        <>
          <DivLastRead>
            <RiTimeLine color={Colors.black50} size={18} />

            <h3>
              {lastCheckin
                ? `Última leitura em ${new Date(lastCheckin).toLocaleString()}`
                : "Sem histórico"}
            </h3>
          </DivLastRead>

          {success ? (
            <StatusCheckContainer>
              <RiCheckboxCircleLine color={Colors.sucess90} size={37.5} />

              <h1 className="title">Check-in realizado!</h1>
            </StatusCheckContainer>
          ) : null}

          {error ? (
            <>
              {error === ReasonError.ALREADY_SCANNED ? (
                <StatusCheckContainer>
                  <IoMdCloseCircleOutline color={Colors.red10} size={37.5} />

                  <h1 className="title">Falha no check-in</h1>

                  <h3>{ReasonError.ALREADY_SCANNED}</h3>
                </StatusCheckContainer>
              ) : null}

              {error === ReasonError.CHECKIN_FAILED ? (
                <StatusCheckContainer>
                  <IoMdCloseCircleOutline color={Colors.red10} size={37.5} />
                  <h1 className="title">Falha no check-in</h1>

                  <h3>{ReasonError.CHECKIN_FAILED}</h3>
                </StatusCheckContainer>
              ) : null}

              {error === ReasonError.USER_TICKET_NOT_ALLOWED_TO_USER ? (
                <StatusCheckContainer>
                  <IoMdCloseCircleOutline color={Colors.red10} size={37.5} />
                  <h1 className="title">Falha no check-in</h1>

                  <h3>{ReasonError.USER_TICKET_NOT_ALLOWED_TO_USER}</h3>
                </StatusCheckContainer>
              ) : null}
            </>
          ) : null}

          {error !== ReasonError.CHECKIN_FAILED ? (
            <ContentContainer>
              <strong>{userTicket?.holder_name}</strong>

              <h4>
                Documento:{" "}
                {userTicket?.holder_cpf
                  ? formatCpf(userTicket?.holder_cpf)
                  : "-"}
              </h4>

              <h4>ID do ingresso: {userTicket?._id}</h4>

              <h4>Evento: {userTicket?.event?.name}</h4>

              <h4>Ingresso: {userTicket?.event_ticket_id?.name}</h4>

              <Row>
                <strong>Check-in:&nbsp;</strong>

                <h4>{userTicket?.formatedCheckinAt}</h4>
              </Row>

              <Row>
                <strong>Check-out:&nbsp;</strong>

                <h4>{userTicket?.formatedCheckouAt}</h4>
              </Row>
            </ContentContainer>
          ) : null}
        </>
      ) : null}

      {error === ReasonError.USER_TICKET_NOT_FOUND ? (
        <ContainerFooterButtons onClick={showModal}>
          <ButtonCode active>
            <span>Inserir código</span>
          </ButtonCode>
        </ContainerFooterButtons>
      ) : null}

      <ModalManualCheckinOut
        isModalVisible={isModalVisible}
        handleCancel={handleCancel}
        handleOk={handleOk}
        getTicketCode={getTicketCode}
        type="checkin"
      />
    </Container>
  );
}
