import { useEffect } from "react";
import { Divider, Spin } from "antd";
import * as yup from "yup";
import {
  BankAccountCard,
  BankAccountContainer,
  CancelButton,
  CardHeader,
  ConfirmButton,
  Container,
  Footer,
  WrapperContainer,
  Header,
  EmptyBankAccount,
  EmptyBankAccountHeader,
  AddBankAccount,
} from "./styles";
import { InputForm } from "components/Form/InputForm";
import {
  RiAccountBoxLine,
  RiAddLine,
  RiInformationLine,
  RiMoneyDollarCircleLine,
} from "react-icons/ri";
import { LoadingOutlined } from "@ant-design/icons";
import { Colors } from "constants/colors";
import {
  GridItems,
  Item,
  SubTitle,
  TextTotal,
  TitleTotal,
} from "../OrganizersBalanceReceivable/styles";
import { formatPrice } from "utils/formatPrice";
import { divideBy100 } from "utils/divideBy100";
import { Card } from "components/ModalGeneralBalanceReceivable/styles";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAuthOrganizerById } from "services/Hook/Organizer";
import { OrganizerResponse } from "types";
import Datas from "constants/Datas";
import survey_search from "assets/survey-search.svg";
import { BalanceProps } from "../OrganizersBalanceReceivable";

interface OrganizerWithdrawProps {
  balance: BalanceProps;
  onCancel: () => void;
  onConfirmWithdraw: (amount: number) => void;
}

type FormSchema = {
  amount: string;
};

const formSchema = yup.object().shape({
  amount: yup.string().required("Valor obrigatório"),
});

const antIcon = (
  <LoadingOutlined style={{ fontSize: 38, color: Colors.primary100 }} spin />
);

function OrganizerWithdraw({
  balance,
  onCancel,
  onConfirmWithdraw,
}: OrganizerWithdrawProps) {
  const { data } = useAuthOrganizerById<OrganizerResponse>(balance.id);
  const bankAccount = data?.bankAccounts[0];

  const {
    register,
    watch,
    setError,
    clearErrors,
    formState: { errors, touchedFields },
    handleSubmit,
  } = useForm<FormSchema>({
    resolver: yupResolver(formSchema),
  });

  const watchAmount = watch("amount");

  const formattedAmount = watchAmount
    ? Number(watchAmount.replace(/\D/g, "")) / 100
    : 0;

  const isValidAmount = formattedAmount >= 0.01;

  const onSubmit = () => {
    onConfirmWithdraw(formattedAmount);
  };

  useEffect(() => {
    const availableBalance = divideBy100(balance?.antecipatedAmount ?? 0);

    clearErrors("amount");

    if (!isValidAmount && touchedFields) {
      setError("amount", {
        message: "O valor de saque precisa ser positivo",
      });
    }

    if (formattedAmount > availableBalance) {
      setError("amount", {
        message:
          "O valor de saque deve ser menor ou igual ao valor disponível para saque",
      });
    }
  }, [
    balance?.antecipatedAmount,
    formattedAmount,
    setError,
    clearErrors,
    isValidAmount,
    touchedFields,
  ]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card>
        <Container>
          <Header>
            <h1>Qual valor você deseja sacar? </h1>

            <Divider />
          </Header>

          <WrapperContainer>
            <InputForm
              {...register("amount")}
              error={errors?.amount}
              isFull
              hasPrefix
              mask="price"
              icon={
                <RiMoneyDollarCircleLine size={17} color={Colors.secondary40} />
              }
              label="Valor do saque"
            />

            <Divider />
          </WrapperContainer>

          <CardHeader>
            <RiAccountBoxLine color={Colors.secondary40} size={25} />

            <span>{balance?.organizerName}</span>
          </CardHeader>

          <GridItems>
            <Item onClick={() => {}}>
              <div className="column-content">
                <TitleTotal>SALDO DISPONÍVEL</TitleTotal>

                <TextTotal>
                  {formatPrice(divideBy100(balance.antecipatedAmount))}
                </TextTotal>

                <div>
                  <RiInformationLine color={Colors.black50} size={18} />

                  <SubTitle>Taxa da transação: R$00,00</SubTitle>
                </div>
              </div>
            </Item>
          </GridItems>

          <BankAccountContainer>
            <CardHeader>
              <RiMoneyDollarCircleLine color={Colors.secondary40} size={25} />

              <span>Dados bancários</span>
            </CardHeader>
          </BankAccountContainer>

          <BankAccountCard>
            {bankAccount ? (
              <>
                <p>
                  {
                    Datas.Banks.find((i) => i.value === bankAccount.bank_id)
                      ?.label
                  }
                </p>

                <span>
                  AG:&nbsp;<p>{bankAccount.agency}</p>
                </span>

                <span>
                  CC:&nbsp;
                  <p>
                    {bankAccount.account}-{bankAccount.digit}
                  </p>
                </span>

                <span>
                  CPF/CNPJ:&nbsp;<p>{data.organizer.document}</p>
                </span>
              </>
            ) : (
              <EmptyBankAccount>
                <EmptyBankAccountHeader>
                  <h2>Você não cadastrou um conta bancária ainda :(</h2>
                  <p>Cadastre uma conta bancária para efetuar saques.</p>
                </EmptyBankAccountHeader>

                <img src={survey_search} alt="survey_search" />

                <div className="divider"></div>

                <AddBankAccount
                  to={`/organizer/profile/financial/${balance.id}/create`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <RiAddLine size={24} />
                  Cadastrar conta bancária
                </AddBankAccount>
              </EmptyBankAccount>
            )}

            {!data && <Spin spinning indicator={antIcon} />}
          </BankAccountCard>
        </Container>
      </Card>

      <Footer>
        <CancelButton onClick={onCancel}>Cancelar</CancelButton>
        <ConfirmButton disabled={!isValidAmount || !bankAccount}>
          Confirmar saque
        </ConfirmButton>
      </Footer>
    </form>
  );
}

export default OrganizerWithdraw;
