import { Colors } from "constants/colors";
import {
  ContainerModal,
  ButtonClear,
  ButtonOk,
  ContainerButtonsModal,
  SelectOption,
  OptionHeader,
} from "./styles";
import { Select, Spin } from "antd";
import { ROLES } from "pages/CollaboratorRegister/misc";
import { RiUserSettingsLine } from "react-icons/ri";
import { TeamUsersRoleEnum } from "services/teams/types";
import { useState } from "react";
import {
  errorNotification,
  successNotification,
} from "components/Notification";
import axios from "axios";
import { doCreateTeamMember } from "services/teams";

import { LoadingOutlined } from "@ant-design/icons";

const { Option } = Select;

interface ModalSelectUserRoleProps {
  onSelect: (role: TeamUsersRoleEnum) => void;
  userEmailInvited: string;
  teamId: string;
}

function ModalSelectUserRole({
  onSelect,
  userEmailInvited,
  teamId,
}: ModalSelectUserRoleProps) {
  const [fetching, setFetching] = useState(false);
  const [selectedRole, setSelectedRole] = useState<
    TeamUsersRoleEnum | undefined
  >();

  const handleSelectUserRole = (role: TeamUsersRoleEnum) => {
    setSelectedRole(role);
  };

  const handleApplyUserRole = async () => {
    if (!selectedRole) return;

    onSelect(selectedRole);

    try {
      setFetching(true);

      await doCreateTeamMember({
        email: userEmailInvited,
        role: selectedRole,
        teamId,
      });

      successNotification("Sucesso.", "Convite(s) enviado(s) com sucesso.");
      setFetching(false);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        errorNotification(
          `Erro ao enviar convite (${userEmailInvited})`,
          error?.response?.data?.message
        );

        setFetching(false);

        return;
      }
    }
  };

  const handleClearUserRole = () => {
    setSelectedRole(undefined);
  };

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 24, color: Colors.white }} spin />
  );

  return (
    <ContainerModal>
      <h1>Selecione o cargo do membro</h1>

      <Select
        optionLabelProp="title"
        placeholder="Insira o tipo de acesso ou selecione um..."
        onChange={(e) => {
          const foundRole = ROLES.find(
            (r) => r.id === (e as unknown as string)
          )?.id;

          if (!foundRole) return;

          handleSelectUserRole(foundRole);
        }}
        value={ROLES.find((r) => r.id === selectedRole)?.title}
      >
        {ROLES.map((role) => (
          <Option key={role.id} value={role.id}>
            <SelectOption>
              <OptionHeader>
                <RiUserSettingsLine color={Colors.primary100} size={20} />
                <strong>{role.title}</strong>
              </OptionHeader>

              <span>{role.description}</span>
            </SelectOption>
          </Option>
        ))}
      </Select>

      <ContainerButtonsModal>
        <ButtonClear onClick={handleClearUserRole}>
          <span>Limpar</span>
        </ButtonClear>

        <ButtonOk disabled={fetching} onClick={handleApplyUserRole}>
          {!fetching ? <span>Aplicar</span> : null}

          <Spin spinning={fetching} indicator={antIcon} />
        </ButtonOk>
      </ContainerButtonsModal>
    </ContainerModal>
  );
}

export default ModalSelectUserRole;
