/* eslint-disable no-throw-literal */
import { Transaction } from "types";
import { useFetchAuth } from "../../useFetch";

import { format, parseISO } from "date-fns";
import { ptBR } from "date-fns/locale";
import { formatPrice } from "utils/formatPrice";
import useSWR from "swr";
import { authClient } from "services/authClient";
import { authPayment } from "services/authPayment";

interface useFinancialGeneralSearchProps {
  paramsString?: any;
  paramsObject?: any;
}

export function useFinancialGeneralSearch<Data = any, Error = any>({
  paramsString,
  paramsObject,
}: useFinancialGeneralSearchProps) {
  const url: string = "/purchase/balance-summary";

  const { data, error, mutate } = useSWR<Data, Error>(
    `${url}-${paramsString}`,
    async () => {
      const { data } = await authClient.post(url, paramsObject);

      return data;
    }
  );

  return { data, error, mutate };
}

interface useFinancialTransactionSearchProps {
  paramsString?: any;
  paramsObject?: any;
  page: string;
}

export function useFinancialTransactionSearch<Data = any, Error = any>({
  page,
  paramsString,
  paramsObject,
}: useFinancialTransactionSearchProps) {
  const partialUrl = `/purchase/balance`;

  const { data, error, mutate, isValidating } = useSWR<Data, Error>(
    `${partialUrl}-${page}-${paramsString}`,
    async () => {
      const url: string = `${partialUrl}?page=${page}&limit=10`;

      const response = await authClient.post(url, paramsObject);

      const newMapData = response?.data?.results?.map(
        (purchase: Transaction) => ({
          ...purchase,
          isOpen: false,
          formattedTotal: formatPrice(purchase?.total ?? 0),
          formattedTotalFee: formatPrice(purchase?.total_fee ?? 0),
          formattedTotalWet: formatPrice(purchase?.total_wet ?? 0),
          formattedSubtotal: formatPrice(purchase?.subtotal ?? 0),
          formattedDate: format(parseISO(purchase.created_at), "dd/MM/yy", {
            locale: ptBR,
          }),

          event: null,
        })
      );

      const newResponse = { ...response.data, results: newMapData };

      return newResponse;
    }
  );

  return { data, error, mutate, isValidating, partialUrl };
}

export function useFinancialTransactionById<Data = any, Error = any>(
  id: string
) {
  const partialUrl = "purchase";

  const { data, error, mutate, isValidating } = useSWR<Data, Error>(
    partialUrl,
    async () => {
      const url: string = `${partialUrl}/${id}`;

      try {
        const response = await authClient.get(url);

        const { data } = response;

        const newMap = {
          ...data,
          isOpen: false,
          formattedTotal: formatPrice(data?.total ?? 0),
          formattedTotalInstallments: formatPrice(
            data?.total_installments ?? 0
          ),
          formattedTotalFee: formatPrice(data?.total_fee ?? 0),
          formattedWithoutRate: formatPrice(data?.total_without_rate ?? 0),
          formattedTotalWet: formatPrice(data?.total_wet ?? 0),
          formattedDate: format(parseISO(data.created_at), "dd/MM/yy", {
            locale: ptBR,
          }),
          formattedDateAndTime: format(
            parseISO(data.created_at),
            "dd MMMM yy - HH:mm'h'",
            {
              locale: ptBR,
            }
          ),
          event_id: {
            ...data?.event_id,
            formattedDate: format(
              parseISO(data?.event_id?.begin_date),
              "dd/MM/yy",
              {
                locale: ptBR,
              }
            ),
            formattedBeginTimeAndDate: format(
              parseISO(data?.event_id?.begin_date),
              "HH:mm'h' '.' dd/MM/yy",
              {
                locale: ptBR,
              }
            ),
          },
        };

        return newMap;
      } catch (error: any) {
        throw {
          message: error.response?.data?.message || "Erro ao buscar os dados",
          status: error.response?.status || 500,
          details: error.response?.data || null,
        };
      }
    }
  );

  return { data, error, mutate, isValidating, partialUrl };
}

interface useFinancialTransferProps {
  paramsString?: string;
}

export function useFinancialTransfer<Data = any, Error = any>({
  paramsString,
}: useFinancialTransferProps) {
  const partialUrl = `/financial/trasfers`;

  const url: string = `${partialUrl}?${paramsString}`;

  const { data, error, mutate } = useFetchAuth<Data, Error>({
    key: `${partialUrl}?${paramsString}`,
    method: "get",
    url,
  });

  return { data, error, mutate, partialUrl };
}

interface ReportPurchaseProps {
  paramsObject?: any;
}

export async function ReportPurchase({ paramsObject }: ReportPurchaseProps) {
  const url = `/purchase/report`;

  const response = await authClient.post(url, paramsObject);

  return response;
}

interface useBalanceOrganizerProps {
  organizerId?: string;
}

export interface OrganizerBalance {
  id: string;
  availableAmount: number;
  pendingAmount: number;
  antecipatedAmount: number;
}

export interface OrganizerBalanceResponse {
  totalAvailableAmount: number;
  totalPendingAmount: number;
  totalAntecipatedAmount: number;
  organizersBalance: Array<OrganizerBalance>;
}

export function useBalanceOrganizer<Error = any>({
  organizerId,
}: useBalanceOrganizerProps) {
  const url: string = `/organizer/balance${
    organizerId ? `/${organizerId}` : ""
  }`;

  const { data, error, mutate } = useSWR<OrganizerBalanceResponse, Error>(
    url,
    async () => {
      const { data } = await authPayment.get<OrganizerBalanceResponse>(url);

      return data;
    }
  );

  return { data, error, mutate };
}
