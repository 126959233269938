import { Colors } from "constants/colors";

import styled from "styled-components";

export const ContainerFilters = styled.div`
  margin-top: 8px;

  /* @media (max-width: 1020px) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  } */

  @media (min-width: 670px) {
    display: none;
  }
`;

export const ContainerTabs = styled.div`
  width: 100%;

  .ant-tabs-tab {
    font-size: 1.03rem;
    font-family: "Poppins";
    font-weight: normal;
    margin-bottom: 0px;
    color: ${Colors.black70};
  }

  .ant-tabs-ink-bar {
    color: ${Colors.primary100};
    background: ${Colors.primary100};
  }

  .ant-tabs-tab-active {
    color: ${Colors.primary100} !important;
    font-weight: 700;
    font-size: 1.03rem;
    margin-bottom: 0px;
    color: ${Colors.primary100};
  }

  .ant-tabs-ink-bar {
    color: ${Colors.primary100};
    background: ${Colors.primary100};
  }

  .ant-tabs-tab-btn {
    font-family: "Poppins";
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    font-family: "Poppins";
    color: ${Colors.primary100} !important;
  }

  .ant-tabs-nav {
    width: 100% !important;
  }

  .ant-tabs-tab {
    display: block;
    flex: 1;
    text-align: center;
  }

  .ant-tabs-nav > div:nth-of-type(1) {
    display: unset !important;
    width: 100% !important;
  }

  .ant-tabs-tab-active {
    color: ${Colors.primary100} !important;
  }
  .ant-tabs-ink-bar {
    color: ${Colors.primary100};
    background: ${Colors.primary100};
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${Colors.primary100} !important;
    font-weight: 500;
  }

  @media (max-width: 800px) {
    .ant-tabs-tab {
      font-family: "Poppins";
      font-weight: normal;
      font-size: 10px;
      line-height: 160%;
      text-transform: uppercase;

      color: ${Colors.black70};
    }

    .ant-tabs-tab-active {
      color: ${Colors.primary100} !important;

      font-weight: bold;
      font-size: 10px;
      line-height: 160%;
      text-transform: uppercase;

      color: ${Colors.primary100};
    }

    .ant-tabs-ink-bar {
      color: ${Colors.primary100};
      background: ${Colors.primary100};
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: ${Colors.primary100} !important;
      font-weight: 500;
    }

    .ant-tabs-nav {
      width: 100% !important;
    }

    .ant-tabs-tab {
      display: block;
      flex: 1;
      text-align: center;
    }

    .ant-tabs-nav > div:nth-of-type(1) {
      display: unset !important;
      width: 100% !important;
    }

    .ant-tabs-tab-active {
      color: ${Colors.primary100} !important;
    }
    .ant-tabs-ink-bar {
      color: ${Colors.primary100};
      background: ${Colors.primary100};
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: ${Colors.primary100} !important;
      font-weight: 500;
    }
  }
`;

export const Title = styled.div`
  color: ${Colors.black100};
  font-family: Cairo;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 0px;

  @media (max-width: 670px) {
    font-family: Cairo;
    font-size: 0.87rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
`;

export const GridGeneralReport = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 0.51rem;
  margin-top: 1.1rem;

  @media (max-width: 1024px) {
    display: none;
  }

  @media (max-width: 1230px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 800px) {
    grid-template-columns: repeat(2, 1fr);

    div:last-child {
      grid-column: 1 / -1;
    }
  }

  @media (max-width: 670px) {
    margin-top: 0.56rem;
  }
`;

export const GridGeneralReportResponsive = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 0.51rem;
  margin-top: 1.1rem;

  @media (min-width: 1024px) {
    display: none;
  }

  @media (max-width: 1230px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 800px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 670px) {
    margin-top: 0.56rem;
  }
`;

export const Divider = styled.div`
  @media (min-width: 800px) {
    display: none;
  }

  height: 1px;
  margin-top: 2.12rem;
  border: 1px solid ${Colors.border20};
`;

export const CardReport = styled.div`
  cursor: pointer;
  width: 100%;
  height: 5.53rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 1.03rem 0.56rem 1.03rem 0.93rem;

  border-radius: 0.65rem;
  border: 1px solid ${Colors.border20};
  background: ${Colors.white};
  box-shadow: 0px 10px 20px 0px rgba(108, 93, 211, 0.06);

  @media (max-width: 670px) {
    height: 95px;
  }

  svg.icon {
    width: 0.93rem;
    height: 0.93rem;
  }

  div.row {
    display: flex;
    flex-direction: row;

    @media (max-width: 670px) {
      flex-direction: column;
    }
  }

  div.column {
    display: flex;
    flex-direction: column;

    margin-left: 1.03rem;

    @media (max-width: 670px) {
      margin-top: 4px;
      margin-left: 0px;
    }

    h3 {
      color: ${Colors.black100};
      font-family: Cairo;
      font-size: 1.21rem;
      font-style: normal;
      font-weight: 700;
      margin-bottom: 0px;

      @media (max-width: 670px) {
        color: #000;
        font-family: Cairo;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 25px;
      }
    }

    h4 {
      color: ${Colors.black30};
      font-family: Open Sans;
      font-size: 0.93rem;
      font-style: normal;
      font-weight: 700;

      @media (max-width: 670px) {
        color: #c9c9c9;
        font-family: Open Sans;
        font-size: 9px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;
      }
    }
  }
`;

const variants: { [key: string]: any } = {
  purple: "rgba(111, 92, 158, 0.10)",
  yellow: "rgba(243, 236, 62, 0.1)",
  orange: "rgba(246, 177, 72, 0.10)",
  red: "rgba(224, 94, 94, 0.10)",
  blue: "rgba(54, 164, 225, 0.10)",
  green: "rgba(54, 225, 164, 0.10)",
  default: "rgba(111, 92, 158, 0.10)",
};

interface CircleIconProps {
  variant?: string;
}

export const CircleIcon = styled.div<CircleIconProps>`
  width: 2.81rem;
  height: 2.81rem;
  border-radius: 50%;
  background-color: ${Colors.secondary10};

  background-color: ${(props) => variants[`${props.variant ?? "default"}`]};

  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 670px) {
    width: 28px;
    height: 28px;
  }

  img {
    width: 1.87rem;
    height: 1.87rem;

    @media (max-width: 670px) {
      width: 14px;
      height: 14px;
    }
  }
`;



export const ButtonPrint = styled.button`
  width: 12.28rem;
  height: 2.81rem;

  cursor: pointer;
  border: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 14px;

  background: ${Colors.white20};

  @media (max-width: 800px) {
    width: 25.73px;
    height: 25.73px;
    border-radius: 50%;

    div.text {
      display: none;
    }

    svg {
      width: 13px;
      height: 13px;
    }
  }

  span {
    font-family: "Cairo";
    font-weight: bold;
    font-size: 0.93rem;
    margin-left: 10px;
    margin-bottom: 0px;
    margin-left: 10px;
    color: ${Colors.red10};
  }
`;

export const HiddenResponsive = styled.div`
  @media (max-width: 670px) {
    display: none;
  }
`;

export const HiddenWeb = styled.div`
  @media (min-width: 670px) {
    display: none;
  }
`;

export const FlexRow = styled.div`
  display: flex;
  margin-top: 2.34rem;
  align-items: center;
  gap: 0.3rem;

  @media (max-width: 670px) {
    margin-top: 1.5rem;
  }

  span {
    color: ${Colors.black30};
    font-family: Open Sans;
    font-size: 0.93rem;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 0px;

    @media (max-width: 670px) {
      color: ${Colors.black30};
      font-family: Open Sans;
      font-size: 9px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: uppercase;
    }
  }
`;
