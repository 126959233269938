import ReactApexChart from "react-apexcharts";

import { useSearchPurchaseChartGeneral } from "hooks/useSearchPuchaseChart";

import { LoadingOutlined } from "@ant-design/icons";

import {
  GridGraphs,
  ContainerGraph,
  ContainerTitle,
  TitleGraph,
  CardGraph,
  Column,
  RowBorderBottom,
  NameRow,
  ValueRow,
  Container,
  EmptyStateContainer,
  ContainerLoading,
} from "./styles";

import pie_chart from "assets/ri_pie-chart-2-line.svg";
import shopping_cart from "assets/ri_shopping-cart-2-line.svg";
import {
  paymentsStatusPercentageFormat,
  paymentsTypePercentageFormat,
  purchasesTypePercentageFormat,
} from "utils/formatKeys";
import { Colors } from "constants/colors";
import { Spin } from "antd";

export default function GeneralChart() {
  const { data: dataPurchaseChart } = useSearchPurchaseChartGeneral({});

  const seriesMostSold =
    dataPurchaseChart?.mostSoldPurchases.map(
      (purchase) => purchase.purchase_count
    ) ?? [];

  const labelsMostSold =
    dataPurchaseChart?.mostSoldPurchases.map((purchase) =>
      purchase?.event_id && purchase?.event_name?.length < 26
        ? purchase?.event_name
        : `${purchase?.event_name?.substring(0, 25)}...`
    ) ?? [];

  const optionsMostSold: any = {
    chart: {
      width: 580,
      type: "donut",
    },
    labels: labelsMostSold,
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              show: false,
            },
            value: {
              show: true,
            },
          },
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    responsive: [
      {
        breakpoint: 768,
        options: {
          chart: {
            width: 300,
            height: 300,
          },
          legend: {
            show: true,
            position: "bottom",
            offsetY: 0,
          },
        },
      },
    ],
    legend: {
      position: "bottom",
      offsetY: 0,
    },
  };

  const purchaseTypeMapMostPaid =
    dataPurchaseChart?.purchasesTypePercentage.reduce((acc, purchase) => {
      acc[purchase.purchase_type] =
        (acc[purchase.purchase_type] || 0) + purchase.percentage;
      return acc;
    }, {} as Record<string, number>) ?? [];

  const categoriesPurchaseType = Object.keys(purchaseTypeMapMostPaid).map(
    (name) => purchasesTypePercentageFormat(name)
  );
  const seriesPurchaseType = [{ data: Object.values(purchaseTypeMapMostPaid) }];

  const optionsPurchaseType: any = {
    chart: {
      type: "bar",
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        barHeight: "48px",
        distributed: true,
        horizontal: true,
        dataLabels: {
          position: "bottom",
        },
      },
    },
    dataLabels: {
      enabled: true,
      textAnchor: "start",
      style: {
        colors: ["#111"],
      },
      formatter: function (val: any, opt: any) {
        return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val + "%";
      },
      offsetX: 0,
      dropShadow: {
        enabled: true,
      },
    },
    legend: {
      position: "right",
      offsetY: 40,
    },
    stroke: {
      width: 1,
      colors: ["#fff"],
    },
    xaxis: {
      categories: categoriesPurchaseType,
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        show: false,
      },
    },
    grid: {
      show: false,
    },
    tooltip: {
      theme: "dark",
      x: {
        show: false,
      },
      y: {
        title: {
          formatter: () => "",
        },
      },
    },
    responsive: [
      {
        breakpoint: 768,
        options: {
          chart: {
            type: "bar",
            width: "78%",
            height: 350,
          },
          plotOptions: {
            bar: {
              horizontal: true,
            },
          },
          legend: {
            position: "bottom",
            horizontalAlign: "center",
          },
          xaxis: {
            labels: {
              show: false,
            },
          },
          yaxis: {
            labels: {
              show: false,
            },
          },
        },
      },
    ],
  };

  const paymentType =
    dataPurchaseChart?.paymentsTypePercentage.reduce((acc, purchase) => {
      acc[purchase.payment_type] =
        (acc[purchase.payment_type] || 0) + purchase.percentage;
      return acc;
    }, {} as Record<string, number>) ?? [];

  const categoriesPaymentType = Object.keys(paymentType).map((name) =>
    paymentsTypePercentageFormat(name)
  );
  const seriesPaymentType = [{ data: Object.values(paymentType) }];

  const optionsPaymentType: any = {
    chart: {
      type: "bar",
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        barHeight: "48px",
        distributed: true,
        horizontal: true,
        dataLabels: {
          position: "bottom",
        },
      },
    },
    dataLabels: {
      enabled: true,
      textAnchor: "start",
      style: {
        colors: ["#111"],
      },
      formatter: function (val: any, opt: any) {
        return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val + "%";
      },
      offsetX: 0,
      dropShadow: {
        enabled: true,
      },
    },
    legend: {
      position: "right",
      offsetY: 40,
    },
    stroke: {
      width: 1,
      colors: ["#fff"],
    },
    xaxis: {
      categories: categoriesPaymentType,
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        show: false,
      },
    },
    grid: {
      show: false,
    },
    tooltip: {
      theme: "dark",
      x: {
        show: false,
      },
      y: {
        title: {
          formatter: () => "",
        },
      },
    },
    responsive: [
      {
        breakpoint: 768,
        options: {
          chart: {
            type: "bar",
            width: "72%",
            height: 550,
          },
          plotOptions: {
            bar: {
              horizontal: true,
            },
          },
          legend: {
            position: "bottom",
            horizontalAlign: "center",
          },
          xaxis: {
            labels: {
              show: false,
            },
          },
          yaxis: {
            labels: {
              show: false,
            },
          },
        },
      },
    ],
  };

  const paidPurchasesPercentage =
    dataPurchaseChart?.paidPurchasesPercentage.reduce((acc, purchase) => {
      acc[purchase.payment_type] =
        (acc[purchase.payment_type] || 0) + purchase.percentage;
      return acc;
    }, {} as Record<string, number>) ?? [];

  const categoriesPaidPurchases = Object.keys(paidPurchasesPercentage).map(
    (name) => paymentsTypePercentageFormat(name)
  );
  const seriesPaidPurchases = [
    { data: Object.values(paidPurchasesPercentage) },
  ];

  const optionsPaidPurchases: any = {
    chart: {
      type: "bar",
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        barHeight: "48px",
        distributed: true,
        horizontal: true,
        dataLabels: {
          position: "bottom",
        },
      },
    },
    dataLabels: {
      enabled: true,
      textAnchor: "start",
      style: {
        colors: ["#111"],
      },
      formatter: function (val: any, opt: any) {
        return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val + "%";
      },
      offsetX: 0,
      dropShadow: {
        enabled: true,
      },
    },
    legend: {
      position: "right",
      offsetY: 40,
    },
    stroke: {
      width: 1,
      colors: ["#fff"],
    },
    xaxis: {
      categories: categoriesPaidPurchases,
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        show: false,
      },
    },
    grid: {
      show: false,
    },
    tooltip: {
      theme: "dark",
      x: {
        show: false,
      },
      y: {
        title: {
          formatter: () => "",
        },
      },
    },
    responsive: [
      {
        breakpoint: 768,
        options: {
          chart: {
            type: "bar",
            width: "72%",
            height: 250,
          },
          plotOptions: {
            bar: {
              horizontal: true,
            },
          },
          legend: {
            position: "bottom",
            horizontalAlign: "center",
          },
          xaxis: {
            labels: {
              show: false,
            },
          },
          yaxis: {
            labels: {
              show: false,
            },
          },
        },
      },
    ],
  };

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 42, color: Colors.primary100 }} spin />
  );

  return (
    <Container>
      <GridGraphs>
        <ContainerGraph>
          <ContainerTitle>
            <img src={shopping_cart} alt="cart" />
            <TitleGraph>Eventos mais vendidos</TitleGraph>
          </ContainerTitle>

          <CardGraph>
            {!dataPurchaseChart ? (
              <ContainerLoading>
                <Spin spinning={true} indicator={antIcon} />
              </ContainerLoading>
            ) : null}

            {dataPurchaseChart &&
            dataPurchaseChart?.mostSoldPurchases?.length > 0 ? (
              <div className="graph">
                <ReactApexChart
                  options={optionsMostSold}
                  series={seriesMostSold}
                  type="donut"
                  width={520}
                />
              </div>
            ) : null}

            {dataPurchaseChart &&
            dataPurchaseChart?.mostSoldPurchases?.length === 0 ? (
              <EmptyStateContainer>
                <h3>Não há nada por aqui :(</h3>

                <p>
                  mude as chaves da pesquisa para ver os resultados desse
                  gráfico
                </p>
              </EmptyStateContainer>
            ) : null}
          </CardGraph>
        </ContainerGraph>

        <ContainerGraph>
          <ContainerTitle>
            <img src={pie_chart} alt="cart" />
            <TitleGraph>Porcentagem de Vendas por tipo</TitleGraph>
          </ContainerTitle>

          <CardGraph>
            {!dataPurchaseChart ? (
              <ContainerLoading>
                <Spin spinning={true} indicator={antIcon} />
              </ContainerLoading>
            ) : null}

            {dataPurchaseChart &&
            dataPurchaseChart?.purchasesTypePercentage?.length > 0 ? (
              <ReactApexChart
                options={optionsPurchaseType}
                series={seriesPurchaseType}
                type="bar"
                height={320}
              />
            ) : null}

            {dataPurchaseChart &&
            dataPurchaseChart?.purchasesTypePercentage?.length === 0 ? (
              <EmptyStateContainer>
                <h3>Não há nada por aqui :(</h3>

                <p>
                  mude as chaves da pesquisa para ver os resultados desse
                  gráfico
                </p>
              </EmptyStateContainer>
            ) : null}
          </CardGraph>
        </ContainerGraph>

        <ContainerGraph>
          <ContainerTitle>
            <img src={pie_chart} alt="cart" />
            <TitleGraph>
              Porcentagem de Conversões para pix boleto e cortesia
            </TitleGraph>
          </ContainerTitle>

          <CardGraph>
            {!dataPurchaseChart ? (
              <ContainerLoading>
                <Spin spinning={true} indicator={antIcon} />
              </ContainerLoading>
            ) : null}

            {dataPurchaseChart &&
            dataPurchaseChart?.paidPurchasesPercentage?.length > 0 ? (
              <ReactApexChart
                options={optionsPaidPurchases}
                series={seriesPaidPurchases}
                type="bar"
                height={220}
              />
            ) : null}

            {dataPurchaseChart &&
            dataPurchaseChart?.paidPurchasesPercentage?.length === 0 ? (
              <EmptyStateContainer>
                <h3>Não há nada por aqui :(</h3>

                <p>
                  mude as chaves da pesquisa para ver os resultados desse
                  gráfico
                </p>
              </EmptyStateContainer>
            ) : null}
          </CardGraph>
        </ContainerGraph>

        <ContainerGraph>
          <ContainerTitle>
            <img src={pie_chart} alt="cart" />

            <TitleGraph>Vendas status da transação</TitleGraph>
          </ContainerTitle>

          <CardGraph>
            {!dataPurchaseChart ? (
              <ContainerLoading>
                <Spin spinning={true} indicator={antIcon} />
              </ContainerLoading>
            ) : null}

            {dataPurchaseChart &&
            dataPurchaseChart?.paymentsStatusPercentage?.length > 0 ? (
              <Column>
                {dataPurchaseChart?.paymentsStatusPercentage.map((item) => (
                  <RowBorderBottom>
                    <NameRow>
                      {paymentsStatusPercentageFormat(
                        item.payment_status ?? ""
                      )}
                    </NameRow>

                    <ValueRow>{item.percentage}%</ValueRow>
                  </RowBorderBottom>
                ))}
              </Column>
            ) : null}

            {dataPurchaseChart &&
            dataPurchaseChart?.paymentsStatusPercentage?.length === 0 ? (
              <EmptyStateContainer>
                <h3>Não há nada por aqui :(</h3>

                <p>
                  mude as chaves da pesquisa para ver os resultados desse
                  gráfico
                </p>
              </EmptyStateContainer>
            ) : null}
          </CardGraph>
        </ContainerGraph>

        <ContainerGraph>
          <ContainerTitle>
            <img src={pie_chart} alt="cart" />
            <TitleGraph>
              Porcentagem de Vendas por forma de pagamento
            </TitleGraph>
          </ContainerTitle>

          <CardGraph>
            {!dataPurchaseChart ? (
              <ContainerLoading>
                <Spin spinning={true} indicator={antIcon} />
              </ContainerLoading>
            ) : null}

            {dataPurchaseChart &&
            dataPurchaseChart?.paymentsTypePercentage?.length > 0 ? (
              <ReactApexChart
                options={optionsPaymentType}
                series={seriesPaymentType}
                type="bar"
                height={390}
              />
            ) : null}

            {dataPurchaseChart &&
            dataPurchaseChart?.paymentsTypePercentage?.length === 0 ? (
              <EmptyStateContainer>
                <h3>Não há nada por aqui :(</h3>

                <p>
                  mude as chaves da pesquisa para ver os resultados desse
                  gráfico
                </p>
              </EmptyStateContainer>
            ) : null}
          </CardGraph>
        </ContainerGraph>
      </GridGraphs>
    </Container>
  );
}
