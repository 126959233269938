import { authClient } from "services/authClient";
import useSWR from "swr";

interface useEventTicketChartSearchProps {
  paramsString?: any;
  paramsObject?: any;
}

export function useEventTicketChartSearch<Data = any, Error = any>({
  paramsString,
  paramsObject,
}: useEventTicketChartSearchProps) {
  const url: string = "/event-ticket/chart";

  const { data, error, mutate } = useSWR<Data, Error>(
    `${url}-${paramsString}`,
    async () => {
      const { data } = await authClient.post(url, paramsObject);

      return data;
    }
  );

  return { data, error, mutate };
}

interface usePurchaseChartSearchProps {
  paramsString?: any;
  paramsObject?: any;
}

export function usePurchaseChartSearch<Data = any, Error = any>({
  paramsString,
  paramsObject,
}: usePurchaseChartSearchProps) {
  const url: string = "/purchase/chart";

  const { data, error, mutate } = useSWR<Data, Error>(
    `${url}-${paramsString}`,
    async () => {
      const { data } = await authClient.post(url, paramsObject);

      return data;
    }
  );

  return { data, error, mutate };
}
