import { Colors } from "constants/colors";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
`;

export const ContainerItems = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1280px;

  padding-right: 38px;
  padding-left: 38px;

  margin-top: 9.14rem;

  width: 100%;

  @media (max-width: 1024px) {
    margin-top: 40px;
    padding-left: 10px;
    padding-right: 10px;
  }

  h1 {
    font-weight: bold;
    font-size: 1.87rem;
    margin-bottom: 0px;
    color: ${Colors.black100};

    @media (max-width: 1024px) {
      margin-left: 20px;
    }

    @media (max-width: 800px) {
      font-weight: bold;
      font-size: 16px;
      line-height: 25px;

      color: ${Colors.black100};
      margin-left: 20px;
    }
  }

  div.bread {
    @media (max-width: 1024px) {
      display: none;
    }
  }
`;

export const HeaderEvent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-top: 54px;

  div.buttons {
    display: flex;
    flex-direction: row;
    align-items: center;

    @media (max-width: 1024px) {
      display: none;
    }

    button {
      cursor: pointer;
      border: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      width: 12.81rem;
      height: 2.81rem;

      background: ${Colors.primary10};
      border-radius: 0.65rem;

      span {
        font-family: "Cairo";
        font-weight: bold;
        font-size: 0.93rem;
        margin-bottom: 0px;

        color: ${Colors.primary100};
        margin-left: 10px;
      }
    }

    button.second {
      margin-left: 12px;
    }
  }
`;

export const ContainerTabs = styled.div`
  margin-top: 1.5rem;

  .ant-tabs-tab {
    font-size: 1.03rem;
    font-family: "Poppins";
    font-weight: normal;
    margin-bottom: 0px;
    color: ${Colors.black70};
  }

  .ant-tabs-ink-bar {
    color: ${Colors.primary100};
    background: ${Colors.primary100};
  }

  .ant-tabs-tab-active {
    color: ${Colors.primary100} !important;
    font-weight: 700;
    font-size: 1.03rem;
    margin-bottom: 0px;
    color: ${Colors.primary100};
  }

  .ant-tabs-ink-bar {
    color: ${Colors.primary100};
    background: ${Colors.primary100};
  }

  .ant-tabs-tab-btn {
    font-family: "Poppins";
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    font-family: "Poppins";
    color: ${Colors.primary100} !important;
  }

  @media (max-width: 1230px) {
    padding-left: 20px;
    padding-right: 20px;
  }

  @media (max-width: 800px) {
    margin-top: 20px;

    .ant-tabs-tab {
      font-family: "Poppins";
      font-weight: normal;
      font-size: 10px;
      line-height: 160%;
      text-transform: uppercase;

      color: ${Colors.black70};
    }

    .ant-tabs-tab-active {
      color: ${Colors.primary100} !important;

      font-weight: bold;
      font-size: 10px;
      line-height: 160%;
      text-transform: uppercase;

      color: ${Colors.primary100};
    }

    .ant-tabs-ink-bar {
      color: ${Colors.primary100};
      background: ${Colors.primary100};
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: ${Colors.primary100} !important;
      font-weight: 500;
    }

    .ant-tabs-nav {
      width: 100% !important;
    }

    .ant-tabs-tab {
      display: block;
      flex: 1;
      text-align: center;
    }

    .ant-tabs-nav > div:nth-of-type(1) {
      display: unset !important;
      width: 100% !important;
    }

    .ant-tabs-tab-active {
      color: ${Colors.primary100} !important;
    }
    .ant-tabs-ink-bar {
      color: ${Colors.primary100};
      background: ${Colors.primary100};
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: ${Colors.primary100} !important;
      font-weight: 500;
    }
  }
`;

export const ContainerBorder = styled.div`
  width: 100%;
  height: 100%;
  padding: 2.81rem 2.34rem;

  border: 1px solid ${Colors.border20};
  border-bottom-left-radius: 0.93rem;
  border-bottom-right-radius: 0.93rem;

  @media (max-width: 800px) {
    padding: 0px;
    border: none;
  }
`;

export const ContainerResponsiveBorder = styled.div``;

export const Title = styled.h2`
  font-family: Cairo;
  font-size: 1.87rem;
  font-weight: 700;
  line-height: 64px;
  text-align: left;
  color: ${Colors.black100};

  @media (max-width: 800px) {
    font-size: 14px;
  }
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 1.4rem;
  gap: 0.84rem;

  @media (max-width: 800px) {
    gap: 0px;
    margin-top: 4px;
  }
`;

export const RowTexts = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 1.03rem;
`;

export const RowTextsCenter = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.37rem;
`;

export const Status = styled.div`
  width: 0.93rem;
  height: 0.93rem;
  border-radius: 100%;
  background-color: #93c77b;
`;

export const TitleRow = styled.h3`
  font-family: Open Sans;
  font-size: 1.21rem;
  font-weight: 400;
  line-height: 1.95rem;
  text-align: left;
  margin-bottom: 0px;
  color: ${Colors.black70};

  @media (max-width: 800px) {
    font-size: 12px;
  }
`;

export const TitleStrongRow = styled.h3`
  font-family: Open Sans;
  font-size: 1.21rem;
  font-weight: 600;
  line-height: 1.95rem;
  text-align: left;
  margin-bottom: 0px;
  color: ${Colors.black100};

  @media (max-width: 800px) {
    font-size: 10px;
  }
`;

export const Divider = styled.div`
  height: 1px;
  border-top: 1px solid ${Colors.border20};
  margin-top: 0.93rem;
`;

export const ContainerBottom = styled.div`
  width: 100%;
  margin-top: 30px;
  padding-top: 30px;
  border-top: 1px solid #e9e7ee;
`;

export const TitleBottom = styled.div`
  font-family: Open Sans;
  font-size: 1.03rem;
  font-weight: 400;
  line-height: 1.4rem;
  text-align: left;
  color: #888996;
`;

export const ContainerGraphs = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 4.17rem;
`;

interface GraphProps {
  position?: "right" | "left";
}

export const Graph = styled.div<GraphProps>`
  width: 100%;
  display: flex;
  justify-content: ${(props) => (props.position ? "flex-end" : "flex-start")};
  align-items: center;
  height: auto;

  .apexcharts-canvas {
    max-width: 100%;
    height: auto;
  }

  @media (max-width: 800px) {
    max-width: 125px;
  }
`;

export const ContainerBottomButtons = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-top: 34px;
  margin-bottom: 40px;

  .divider {
    width: 100%;
    height: 1px;

    border-top: 1px solid ${Colors.border20};
  }

  @media (min-width: 800px) {
    display: none;
  }
`;

export const ContainerLoading = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
