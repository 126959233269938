import { authPayment } from "services/authPayment";

interface DoWithdrawDTO {
  organizer_id: string;
  amount: number;
  password: string;
}

export function doWithdraw(body: DoWithdrawDTO) {
  return authPayment.post("/organizer/withdraw", body);
}

export function doWithdrawAnticipable(body: DoWithdrawDTO) {
  return authPayment.post("/organizer/withdraw-antecipated", body);
}
