import { useSearchParams } from "react-router-dom";
import { Container, FilterContent } from "./styles";

interface ParticipantsHeaderFilterProps {
  filterBy?: string[];
  defaultFilter?: string;
  onChange: (event: string) => void;
  selected: string;
}

function ParticipantsHeaderFilter({
  filterBy = [],
  onChange,
  selected,
}: ParticipantsHeaderFilterProps) {
  const [searchParams, setSearchParams] = useSearchParams();

  const handleChangeFilter = (filter: string) => {
    onChange(filter);

    setSearchParams({
      ...Object.fromEntries([...searchParams]),
      checkin: filter,
      page: "1",
    });

    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <Container>
      <FilterContent>
        {filterBy?.map((filter) => (
          <span
            className={selected === filter ? "active" : undefined}
            key={filter}
            onClick={() => handleChangeFilter(filter)}
          >
            {filter}
          </span>
        ))}
      </FilterContent>
    </Container>
  );
}

export default ParticipantsHeaderFilter;
