import { useCallback, useMemo, useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { RiArrowLeftLine } from "react-icons/ri";
import { doWithdraw } from "services/organizer";
import { Colors } from "constants/colors";
import { errorNotification } from "components/Notification";
import { ESteps, ModalGeneralBalanceAvailableProps } from "./types";
import OrganizerBalanceAvailable, {
  BalanceProps,
} from "./components/OrganizersBalanceAvailable";
import { ContainerHeader, ContainerModal, IconButton } from "./styles";
import { Spin } from "antd";
import OrganizerWithdraw from "./components/OrganizerWithdraw";
import ConfirmPassword from "./components/ConfirmPassword";

const antIcon = (
  <LoadingOutlined style={{ fontSize: 38, color: Colors.primary100 }} spin />
);

type AvailableBalanceProps = ModalGeneralBalanceAvailableProps & {
  closeModal: () => void;
};

function AvailableBalance({
  balance,
  onWithdraw,
  closeModal,
}: AvailableBalanceProps) {
  const [currentStep, setCurrentStep] = useState<ESteps>(ESteps.BALANCE_LIST);
  const [selectedOrganizer, setSelectedOrganizer] = useState<BalanceProps>();
  const [amount, setAmount] = useState(0);
  const [fetching, setFetching] = useState(false);

  const handleSetSelectedOrganizer = (balance: BalanceProps) => {
    setSelectedOrganizer(balance);
    setCurrentStep(ESteps.WITHDRAW);
  };

  const handleConfirmAmount = (value: number) => {
    setAmount(value * 100);
    setCurrentStep(ESteps.CONFIRM_PASSWORD);
  };

  const handleSubmitWithdraw = useCallback(
    async (password: string) => {
      setFetching(true);

      if (!selectedOrganizer?.id) return;

      try {
        await doWithdraw({
          organizer_id: selectedOrganizer.id,
          amount,
          password,
        });

        onWithdraw();
        closeModal();
        setCurrentStep(ESteps.BALANCE_LIST);
      } catch (error: any) {
        errorNotification(
          "Ocorreu um erro",
          error?.response?.data?.message ?? "Tente novamente mais tarde."
        );
      } finally {
        setFetching(false);
      }
    },
    [amount, selectedOrganizer?.id, onWithdraw, closeModal]
  );

  const handleGoBack = useCallback(() => {
    setCurrentStep((prev) => (prev >= 1 ? prev - 1 : 0));
  }, []);

  const STEPS = useMemo(() => {
    return [
      {
        step: ESteps.BALANCE_LIST,
        title: "Saldo disponível",
        canGoBack: false,
        component: (
          <OrganizerBalanceAvailable
            organizersBalances={balance?.organizersBalance ?? []}
            onWithdraw={handleSetSelectedOrganizer}
          />
        ),
      },
      {
        step: ESteps.WITHDRAW,
        title: "Fazer saque",
        canGoBack: true,
        component: selectedOrganizer ? (
          <OrganizerWithdraw
            balance={selectedOrganizer}
            onCancel={() => {
              closeModal();
              setCurrentStep(ESteps.BALANCE_LIST);
            }}
            onConfirmWithdraw={handleConfirmAmount}
          />
        ) : null,
      },
      {
        step: ESteps.CONFIRM_PASSWORD,
        title: "Fazer saque",
        canGoBack: true,
        component: (
          <ConfirmPassword
            fetching={fetching}
            onConfirmPassword={handleSubmitWithdraw}
            onClose={() => {
              closeModal();
              setCurrentStep(ESteps.BALANCE_LIST);
            }}
          />
        ),
      },
    ];
  }, [
    balance?.organizersBalance,
    selectedOrganizer,
    fetching,
    handleSubmitWithdraw,
    closeModal,
  ]);

  const step = STEPS[currentStep];

  return (
    <Spin spinning={!balance} indicator={antIcon}>
      <ContainerModal>
        <ContainerHeader>
          {step.canGoBack ? (
            <IconButton onClick={handleGoBack} type="button">
              <RiArrowLeftLine size={25} color={Colors.primary100} />
            </IconButton>
          ) : null}

          <h1>{step.title}</h1>
        </ContainerHeader>

        {step.component}
      </ContainerModal>
    </Spin>
  );
}

export default AvailableBalance;
