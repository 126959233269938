import { RiHandCoinLine, RiRefundLine, RiTimeLine } from "react-icons/ri";

import { HeaderFilter } from "components/HeaderFilter";
import { sortingFilterTicketOffice } from "constants/filters";

import {
  Divider,
  HiddenResponsive,
} from "pages/Financial/components/Tabs/General/Graphs/styles";

import { FiltersByDataType } from "components/FiltersByDataType";
import { ModalFilterByData } from "components/ModalFilterByData";
import { ModalFilterByEvent } from "components/ModalFilterByEvent";
import { ModalFilterByTicket } from "components/ModalFilterByTicket";
import { OverviewItem } from "components/OverviewItem/Index";

import {
  Container,
  FilterContainer,
  GridOverviewItems,
  OverviewContainer,
} from "./styles";

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { useSearchUserTicketBalanceSummary } from "hooks/useSearchUserTicketBalanceSummary";
import useDefaultFilterByToday from "hooks/useDefaultFilterByToday";
import { Graphs } from "./Graphs";

ChartJS.register(ArcElement, Tooltip, Legend);

function TicketOfficeView() {
  useDefaultFilterByToday();
  const { data } = useSearchUserTicketBalanceSummary({});

  const overviewItems = [
    {
      name: "CONFIRMADOS",
      quantity: data?.PAID || 0,
      color: "rgba(119, 214, 103, 1)",
      bgColor: "rgba(119, 214, 103, 0.10)",
      icon: <RiHandCoinLine />,
    },
    {
      name: "PENDENTES",
      quantity: data?.PENDING || 0,
      color: "rgba(246, 177, 72, 1)",
      bgColor: "rgba(246, 177, 72, 0.10)",
      icon: <RiTimeLine />,
    },
    {
      name: "CANCELADOS",
      quantity: data?.REFUND || 0,
      color: "rgba(224, 94, 94, 1)",
      bgColor: "rgba(224, 94, 94, 0.1)",
      icon: <RiRefundLine />,
    },
  ];

  return (
    <Container>
      <HiddenResponsive>
        <HeaderFilter filters={sortingFilterTicketOffice} />
      </HiddenResponsive>

      <FilterContainer>
        <hr />
        <FiltersByDataType />
      </FilterContainer>

      <OverviewContainer>
        <h2 className="subtitle">Visão Geral</h2>
        <GridOverviewItems>
          {overviewItems.map((item, index) => (
            <OverviewItem
              key={index}
              name={item.name}
              quantity={item?.quantity}
              color={item.color}
              bgColor={item.bgColor}
            >
              {item.icon}
            </OverviewItem>
          ))}
        </GridOverviewItems>
      </OverviewContainer>

      <Divider />

      <Graphs />

      <ModalFilterByData />
      <ModalFilterByEvent />
      <ModalFilterByTicket />
    </Container>
  );
}

export default TicketOfficeView;
