import { useState } from "react";
import { GoChevronDown, GoChevronUp } from "react-icons/go";
import { Colors } from "constants/colors";
import {
  CardAccordion,
  ContainerBorder,
  DropdownContainer,
  ItemsContainer,
  ItemWrapper,
} from "./styles";
import { formatCpf } from "utils/formatCpf";
import { Divider } from "antd";
import { formatDate } from "utils/formatDate";
import { parseISO } from "date-fns";

interface ParticipantsAccordionItemProps {
  defaultOpen?: boolean;
  holderName?: string;
  holderDocument?: string;
  holderId?: string;
  ticketId?: string;
  ticketType?: string;
  checkInAt?: string;
  checkOutAt?: string;
}

function ParticipantsAccordionItem({
  defaultOpen = false,
  holderName,
  holderDocument,
  holderId,
  ticketId,
  ticketType,
  checkInAt,
  checkOutAt,
}: ParticipantsAccordionItemProps) {
  const [open, setOpen] = useState(defaultOpen);

  const handleToggleActive = () => {
    setOpen((prev) => !prev);
  };

  return (
    <>
      <CardAccordion onClick={handleToggleActive} active={open}>
        <div className="content">
          <div className="texts">
            <h2>{holderName}</h2>
            ID: {holderId}
          </div>
        </div>

        {open ? (
          <GoChevronUp size={22} color={Colors.secondary50} />
        ) : (
          <GoChevronDown size={22} color={Colors.secondary50} />
        )}
      </CardAccordion>

      {open ? (
        <DropdownContainer>
          <ContainerBorder>
            <ItemsContainer>
              <ItemWrapper>
                <b>CPF:</b>
                <p>
                  {holderDocument ? formatCpf(holderDocument) : "Não informado"}
                </p>
              </ItemWrapper>

              <ItemWrapper>
                <b>ID do ingresso:</b>
                <p>{ticketId}</p>
              </ItemWrapper>

              <ItemWrapper>
                <b>Tipo do ingresso:</b>
                <p>{ticketType ?? "-"}</p>
              </ItemWrapper>

              <ItemWrapper>
                <b>Acompanhantes:</b>
                <p>-</p>
              </ItemWrapper>
            </ItemsContainer>

            <Divider />

            <ItemsContainer>
              <ItemWrapper>
                <b>Check-in:</b>

                <p>
                  {checkInAt
                    ? formatDate(parseISO(checkInAt))
                    : "Não realizado"}
                </p>
              </ItemWrapper>

              <ItemWrapper>
                <b>Check-out:</b>

                <p>
                  {checkOutAt
                    ? formatDate(parseISO(checkOutAt))
                    : "Não realizado"}
                </p>
              </ItemWrapper>
            </ItemsContainer>
          </ContainerBorder>
        </DropdownContainer>
      ) : null}
    </>
  );
}

export default ParticipantsAccordionItem;
