import { Colors } from "constants/colors";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const GridGraphs = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 11px;
  margin-top: 2.81rem;

  row-gap: 2.81rem;

  @media (max-width: 1024px) {
    grid-template-columns: repeat(1, 1fr);
    row-gap: 1.5rem;
    margin-top: 1.25rem;
    align-items: center;
    justify-content: center;
  }
`;

export const ContainerGraph = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const ContainerTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  row-gap: 10px;

  img {
    width: 1.68rem;
    height: 1.68rem;

    @media (max-width: 670px) {
      width: 1.25rem;
      height: 1.25rem;
    }
  }
`;

export const TitleGraph = styled.h4`
  color: ${Colors.black100};
  font-family: Cairo;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  margin-left: 0.46rem;
  margin-bottom: 0px;

  @media (max-width: 670px) {
    font-family: Cairo;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    margin-left: 0.37rem;
  }
`;

export const CardGraph = styled.div`
  width: 100%;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 1.12rem;

  padding: 1.03rem 1.87rem;
  border-radius: 0.65rem;
  border: 1px solid ${Colors.border20};
  background: ${Colors.white};
  box-shadow: 0px 10px 20px 0px rgba(108);

  div.graph {
    height: 100%;

    @media (max-width: 640px) {
      padding-top: 8px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  @media (max-width: 800px) {
    border-radius: 5px;
    border: 1px solid ${Colors.border20};
    background: #fdfdfd;
    box-shadow: 0px 5px 20px 0px rgba(108, 93, 211, 0.08);
    min-height: 120px;
  }

  @media (max-width: 670px) {
    padding: 0.62rem 0.75rem;
  }
`;

export const Column = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const RowBorderBottom = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 0px 0.46rem 0px;
  border-bottom: 1px solid #efedf4;
`;

export const NameRow = styled.h3`
  color: ${Colors.gray10};
  font-family: Poppins;
  font-size: 0.93rem;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 0px;

  @media (max-width: 670px) {
    font-family: Poppins;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`;

export const ValueRow = styled.h4`
  color: ${Colors.secondary40};
  font-family: Poppins;
  font-size: 0.93rem;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 0px;

  @media (max-width: 670px) {
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;

export const RowResume = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0.93rem 0px 10px 0px;
`;

export const NameResume = styled.h3`
  color: ${Colors.gray10};
  font-family: Poppins;
  font-size: 0.93rem;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 0px;

  @media (max-width: 670px) {
    font-family: Poppins;
    font-size: 11px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
`;

export const ValueResume = styled.h4`
  color: ${Colors.secondary100};
  font-family: Poppins;
  font-size: 0.93rem;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 0px;

  @media (max-width: 670px) {
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
`;

export const Divider = styled.div`
  @media (min-width: 800px) {
    display: none;
  }

  height: 1px;
  margin-top: 2.12rem;
  border: 1px solid ${Colors.border20};
`;

export const HiddenResponsive = styled.div`
  @media (max-width: 670px) {
    display: none;
  }
`;

export const EmptyStateContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  gap: 0.93rem;

  @media(max-width: 640px){
    gap: 0.33rem;
    }

  h3 {
    font-family: Open Sans;
    font-size: 1.21rem;
    font-weight: 700;
    line-height: 1.95rem;
    color: ${Colors.gray60};

    @media(max-width: 640px){
      font-size: 14px;
    }
  }

  p {
    font-family: Open Sans;
    font-size: 1.03rem;
    font-weight: 400;
    line-height: 1.65rem;
    text-align: center;
    color: ${Colors.gray60};

    @media(max-width: 640px){
      font-size: 12px;
      line-height: 1rem;
    }
  }
`;

export const ContainerLoading = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
