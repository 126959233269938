import { Colors } from "constants/colors";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid ${Colors.border20};
  border-radius: 14px;
  background: ${Colors.white};

  width: 100%;
`;

export const FilterContent = styled.div`
  padding: 14px 25px;

  display: flex;
  align-items: center;
  column-gap: 40px;

  > span {
    font-family: "Cairo";
    font-weight: 400;
    font-size: 18px;
    line-height: 33px;
    color: ${Colors.black20};

    &.active {
      font-weight: 700;
      color: ${Colors.black100};
    }

    &:hover {
      cursor: pointer;
    }
  }

  @media (max-width: 1024px) {
    > span {
      font-size: 16px;
    }
  }
`;
