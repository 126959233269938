// import { Transaction } from "types";
// import { useFetchAuth } from "../../useFetch";

// import { format, parseISO } from "date-fns";
// import { ptBR } from "date-fns/locale";
// import { formatPrice } from "utils/formatPrice";
import useSWR from "swr";
import { authClient } from "services/authClient";
import { format, parseISO } from "date-fns";
import { ptBR } from "date-fns/locale";

export interface UserTicketBalance {
  deleted_at: string | null;
  _id: string;
  ticket_code: string;
  event: {
    name: string;
    address_city: string;
    address_district: string;
    address_name: string;
    address_number: string;
    address_state: string;
    address_street: string;
    begin_date: string;
    begin_hour: string;
    end_date: string;
    end_hour: string;
  };
  user: {
    _id?: string;
    name?: string;
    email?: string;
    cell_phone?: string;
    document?: string;
  };
  event_ticket_id: {
    _id: string;
    name: string;
    description: string;
  };
  purchase_id: string;
  holder_name: string;
  holder_email: string;
  holder_cpf: string;
  checkin: boolean;
  checkin_at: string | null;
  checkout: boolean;
  checkout_at: string | null;
  formatedCheckinAt: string;
  formatedCheckouAt: string;
  status: boolean;
  ticket: number;
}

interface useTicketBalanceProps {
  paramsString?: any;
  paramsObject?: any;
  page: string;
}

export function useTicketBalance<Data = any, Error = any>({
  page,
  paramsString,
  paramsObject,
}: useTicketBalanceProps) {
  const partialUrl = `/user-ticket/balance`;

  const { data, error, mutate, isValidating } = useSWR<Data, Error>(
    `${partialUrl}-${page}-${paramsString}`,
    async () => {
      const url: string = `${partialUrl}?page=${page}&limit=10`;

      const response = await authClient.post(url, paramsObject);

      const newMapData = response?.data?.results?.map(
        (userTicket: UserTicketBalance) => {
          const formatedCheckinAt =
            userTicket?.checkin && userTicket?.checkin_at
              ? format(parseISO(userTicket?.checkin_at), "dd/MM/yyyy HH:mm", {
                  locale: ptBR,
                })
              : "";

          const formatedCheckouAt =
            userTicket?.checkout && userTicket?.checkout_at
              ? format(parseISO(userTicket?.checkout_at), "dd/MM/yyyy HH:mm", {
                  locale: ptBR,
                })
              : "";

          return {
            ...userTicket,
            formatedCheckinAt,
            formatedCheckouAt,
            event: {
              ...userTicket.event,
              begin_date: userTicket.event?.begin_date
                ? format(
                    parseISO(userTicket?.event?.begin_date),
                    "dd' de 'MMMM'",
                    {
                      locale: ptBR,
                    }
                  )
                : "",
              begin_hour: userTicket.event?.begin_hour
                ? `${userTicket.event?.begin_hour
                    .split(" ")[1]
                    .substring(0, 5)} h`
                : "",
              end_date: userTicket.event?.end_date
                ? format(
                    parseISO(userTicket?.event?.end_date),
                    "dd' de 'MMMM'",
                    {
                      locale: ptBR,
                    }
                  )
                : "",
              end_hour: userTicket.event?.end_hour
                ? `${userTicket.event?.end_hour
                    .split(" ")[1]
                    .substring(0, 5)} h`
                : "",
            },
          };
        }
      );

      const newResponse = { ...response.data, results: newMapData };

      return newResponse;
    }
  );

  return { data, error, mutate, isValidating, partialUrl };
}

interface useUserTicketBalanceSummaryProps {
  paramsString?: any;
  paramsObject?: any;
}

export function useUserTicketBalanceSummary<Data = any, Error = any>({
  paramsString,
  paramsObject,
}: useUserTicketBalanceSummaryProps) {
  const url: string = "/user-ticket/balance-summary";

  const { data, error, mutate } = useSWR<Data, Error>(
    `${url}-${paramsString}`,
    async () => {
      const response = await authClient.post(url, paramsObject);

      const { data } = response;

      return data;
    }
  );

  return { data, error, mutate };
}

interface ReportUserTicketProps {
  paramsObject?: any;
}

export async function ReportUserTicket({
  paramsObject,
}: ReportUserTicketProps) {
  const url = `/user-ticket/report`;

  const response = await authClient.post(url, paramsObject);

  return response;
}

export interface IResendUserTicketByEmail {
  userTicketId: string;
}

export async function ResendUserTicket({
  userTicketId,
}: IResendUserTicketByEmail) {
  const url = `/user-ticket/resend-ticket/${userTicketId}`;
  const response = await authClient.get(url);
  return response;
}

interface useUserTicketChartProps {
  paramsString?: any;
  paramsObject?: any;
}

export function useUserTicketChart<Data = any, Error = any>({
  paramsString,
  paramsObject,
}: useUserTicketChartProps) {
  const url: string = "/user-ticket/chart";

  const { data, error, mutate } = useSWR<Data, Error>(
    `${url}-${paramsString}`,
    async () => {
      const response = await authClient.post(url, paramsObject);

      const { data } = response;

      return data;
    }
  );

  return { data, error, mutate };
}
